<template>
  <main style="padding-top: 100px;">
    <div class="page_header element_to_stick">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12">
            <div class="search_bar_list">
              <b-input :class="{ searched :searchTerm.length }" type="text" @keyup.enter="search" v-model="searchTerm" class="form-control" placeholder="Cauta categorie, restaurant, oras"></b-input>
              <button v-if="searchTerm.length" class="reset" type="submit" @click="resetSearch()"><i class="mdi mdi-close-box"></i></button>
              <button type="submit" @click="search"><i class="mdi mdi-magnify"></i></button>
            </div>
          </div>
          <div class="most-searched">
          <div class="title">Cele mai cautate cuvinte</div>
          <b-button @click="clickMostSearched(cat)" size="sm" v-for="cat in mostSearched" :key="cat" variant="outline-primary">{{cat}}</b-button>
        </div>
        </div>
        
        <!-- /row -->
      </div>
    </div>
    <!-- /page_header -->
    <div class="container margin_30_20">
      <b-row class="cities-restaurants">
        <b-col
            md="4"
            sm="12"
            v-for="(restaurant, index) in restaurants"
            :key="index"
        >
          <div class="strip">
            <figure>
              <b-img :src="restaurant.image_url" fluid-grow></b-img>
              <router-link class="strip_info" :to="{ name: 'public.restaurant.details', params: { slug: restaurant.slug } }">
                <small class="inchis" v-if="!restaurant.is_opened"><i class="mdi pr-1 mdi-timer-outline "></i>Precomanda</small>
                <small id="estimated-time" v-if="restaurant.is_opened && restaurant.estimated_time"><i class="mdi pr-1 mdi-timer-outline "></i>{{restaurant.estimated_time}}</small>
                <small v-if="restaurant.text_promo" id="text-promo">{{restaurant.text_promo}}</small>
                <div class="item_title">
                  <h3>{{ restaurant.name }}</h3>
                  <small>{{ restaurant.street }}, {{ restaurant.city }}</small>
                </div>
              </router-link>
            </figure>
          </div>
        </b-col>
        <div v-if="restaurants.length < 1">Vă rugam căutați alta categorie de produse, nume de restaurant sau nume oraș.</div>
      </b-row>
    </div>
  </main>
</template>

<script>
import PublicCities from '@/components/publicEndpoints/cities'

export default {
  name: 'CityDetails',
  components: {

  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  data() {
    return {
      restaurants: [],
      mostSearched: [
        'pizza', 'paste', 'shaorma', "meniul zilei", 'peste', 'desert', 'burger', 'salate', 'ciorbe'
      ],
      paging: {
        page: 1,
        per_page: 12,
        total: 10,
        lastPage: 100
      },
      filters: {},
      searchTerm: this.$route.params.searchTerm || '',
      lazyLoadingBusy: false,
      endOfResults: false,
      isBusy: false
    }
  },
  methods: {
    resetSearch() {
      this.searchTerm = '';
      this.search();
    },
    clickMostSearched(cat) {
      this.searchTerm = cat;
      this.search();
    },
    search() {
      this.endOfResults = false;
      this.filters = {};
      this.paging.search = this.searchTerm
      this.paging.page = 1
      this.paging.lastPage = 1000
      this.getRestaurants(true)
    },
    getRestaurants(clearResults = false) {
      if (this.endOfResults || this.isBusy) {
        return;
      }
      this.isBusy = true
      this.paging.search = this.searchTerm;
      PublicCities.get(this.$route.params.slug, this.paging, this.filters).then(response => {
        if (response.data.data.length === 0) {
          this.endOfResults = true;
        }
        if (clearResults) {
          this.restaurants = response.data.data
        } else {
          response.data.data.forEach(restaurant =>  {
            if (!this.restaurantExists(restaurant.id)) {
              this.restaurants.push(restaurant)
            }
          })
        }
        this.paging.total = response.data.meta.total
        this.paging.lastPage = response.data.meta.last_page
        this.isBusy = false
        this.lazyLoadingBusy = false;
      })
    },
    handleScroll: function(e) {
      if (this.lazyLoadingBusy) {
        return;
      }
      const bodyHeight = e.target.body.offsetHeight;
      const innerHeight = window.innerHeight;
      const scrollY = window.scrollY;
      const beforeBottom = document.querySelector('.cities-restaurants') ? document.querySelector('.cities-restaurants').offsetTop : 500;
      if ((innerHeight + scrollY) >= bodyHeight - beforeBottom) {
        this.lazyLoadingBusy = true;
        this.getRestaurants()
        this.paging.page++;
      }
    },
    restaurantExists(id) {
      return this.restaurants.some(el => {
        return el.id === id;
      });
    }
  },
  mounted() {
    this.getRestaurants(true)
  }
}
</script>

<style scoped>
#estimated-time {
  /* font-weight: bold; */
  background: #4E7BC5;
  color: #fff;
  left: 0;
  border-radius: 0;
}
#text-promo {
  top: 37px;
  left: 0;
  background: red;
  color: white;
  border-radius: 0;
}
.most-searched {
  margin-top: 10px;
}
.most-searched .btn {
  margin: 5px;
}
.searched {
  box-shadow: 0px 0px 0px 2px #4E7BC5!important;
  padding-left: 45px!important;
}
.reset {
  left: 0px;
  width: 20px;
}
.mdi-magnify {
  font-size: 28px;
}
.btn-outline-primary:hover {
  background-color: #4E7BC5!important;
  border-color: #4E7BC5!important;
  color: #fff!important;
}
.btn-outline-primary {
  color: #4E7BC5!important;
  border-color: #4E7BC5!important;
}
.title {
  margin-left: 5px;
}
.btn {
  border-radius: 0!important;
}
.inchis {
  left: 0!important;
  border-radius: 0!important;
}
</style>